import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const svgIconNames = [
  'help',
  'save',
  'info',
  'date',
  'single-developer',
  'team',
  'new-single-developer',
  'tag',
  'arrow-up',
  'arrow-down',
  'tree-arrow-up',
  'tree-arrow-down',
  'tree-arrow-right',
  'report',
  'teams-group',
  'report-icon',
  'edit-teams',
  'edit-profiles',
  'hiring-icon',
  'apartment',
  'percent',
  'person',
  'lines',
  'depth',
  'alias',
  'file',
  'directory',
  'submission',
  'profile-icon',
  'candidate',
  'candidate-profile',
  'degree',
  'cv-button',
  'upload-icon',
  'delete',
  'upload-logo',
  'icon',
  'on-hold-icon',
  'done-icon',
  'done',
  'generate',
  'hire-member-icon',
  'merge',
  'error',
  'scan-type',
  'organization-icon',
  'submissions-icon',
  'unpublish-icon',
  'github',
  'shared-with-me',
  'my-runs',
  'investor-hub',
  'add-startup',
  'linkedin',
  'file-uplaod',
  'download-icon',
  'pdf-file',
  'jpg-file',
  'png-file',
  'pptx-file',
  'txt-file',
  'analysis-icon',
  'scans-icon',
  'hiring-new-icon',
  'project-hub',
  'single-project',
  'codenteam-icon',
  'lamp-icon',
  'code',
  'bug-icon',
  'lic-scan-icon',
  'startup-rocket-icon',
  'shield-icon',
];

@NgModule({
  imports: [MatIconModule, HttpClientModule],
  exports: [MatIconModule],
})
export class IconsModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    for (const svgIconName of svgIconNames) {
      this.matIconRegistry.addSvgIcon(
        svgIconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/ui/icons/${svgIconName}.svg`
        )
      );
    }
  }
}
